import { useEffect } from 'react';
import {
  APPLICATION_CATEGORY,
  JOB_LIST_CATEGORY,
  matomoEvent,
  matomoEventTypeMap,
  MOBILE_CATEGORY_PREFIX,
  PROFILE_CATEGORY,
  WEB_CATEGORY_PREFIX,
} from 'shared/utils/matomoConfig';

const getMatomoEventTracker = () => {
  // eslint-disable-next-line no-underscore-dangle
  return typeof window === 'object' && window._paq ? window._paq : [];
};

export const matomoEventTracker = (eventType, deviceType) => {
  const { eventCategory, eventAction, eventName } = matomoEvent[eventType];
  getMatomoEventTracker().push(['trackEvent', `${deviceType}_${eventCategory}`, eventAction, `${deviceType}_${eventName}`]);
};

export const setSubmitMatomoEvent = (eventName, deviceType) => {
  getMatomoEventTracker().push([
    'trackEvent',
    `${deviceType}_${APPLICATION_CATEGORY}`,
    'Submit',
    `${deviceType}_${APPLICATION_CATEGORY}_${eventName}`,
  ]);
};

export const setJobLinkMatomoEvent = (jobTitle, deviceType) => {
  getMatomoEventTracker().push([
    'trackEvent',
    `${deviceType}_${JOB_LIST_CATEGORY}`,
    'Click',
    `${deviceType}_${JOB_LIST_CATEGORY}_${jobTitle}`,
  ]);
};

export const setApplicationInfoMatomoEvent = (eventName, deviceType) => {
  getMatomoEventTracker().push([
    'trackEvent',
    `${deviceType}_${PROFILE_CATEGORY}`,
    'Click',
    `${deviceType}_${PROFILE_CATEGORY}_${eventName}`,
  ]);
};

export function handleClickEvent(event) {
  const deviceType = event.pointerType === 'mouse' ? WEB_CATEGORY_PREFIX : MOBILE_CATEGORY_PREFIX;
  const eventType = this.getAttribute('data-matomo');
  if (eventType) {
    if (eventType === matomoEventTypeMap.jobList.jobLink) {
      const jobTitle = this.textContent;
      setJobLinkMatomoEvent(jobTitle, deviceType);
    } else {
      matomoEventTracker(eventType, deviceType);
    }
  }
}

export const useMatomoEventTracker = () => {
  useEffect(() => {
    const matomoListenedElement = document.querySelectorAll('[data-matomo]');
    matomoListenedElement.forEach((element) => element.addEventListener('click', handleClickEvent, true));

    return () => matomoListenedElement.forEach((element) => element.removeEventListener('click', handleClickEvent, true));
  });
};
