import React, { useContext, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { arrayOf, shape, string } from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MobileContext } from 'shared/contexts';
import Link from 'shared/vi-components/link';
import s from './style.less';

const SitemapGroup = ({ groupData }) => {
  useStyles(s);
  const { isMobile } = useContext(MobileContext);
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <section className={classnames(s.Container, isExpanded ? s.IsExpanded : '')}>
      <Link
        className={s.GroupTitle}
        hasLinkStyle={false}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <label>{t(groupData.label.textKey)}</label>
        <i className={classnames('ri-arrow-down-s-line', 'icon-arrow')} />
      </Link>
      <ul className={s.GroupList}>
        {groupData.list.map((item) => (
          <li key={item.textKey}>
            {!isMobile && item.qrCodeImage ? (
              <div className={s.QrCodePopupContainer}>
                <span data-matomo={item.matomoEventType}>{t(item.textKey)}</span>
                <div className={s.QrCodePopup}>
                  <img className={s.QrCode} src={item.qrCodeImage} alt={t(item.textKey)} data-matomo={item.matomoEventType} />
                </div>
              </div>
            ) : (
              <Link hasLinkStyle={false} to={item.link} testId={item.textKey.replace(/\./g, '-')} matomoEventType={item.matomoEventType}>
                {t(item.textKey)}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

SitemapGroup.propTypes = {
  groupData: shape({
    label: shape({
      textKey: string.isRequired,
    }),
    list: arrayOf(
      shape({
        textKey: string.isRequired,
        link: string.isRequired,
      })
    ),
  }).isRequired,
};

export default SitemapGroup;
