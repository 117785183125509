import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { string } from 'prop-types';
import classnames from 'classnames';
import s from './style.less';

const Tag = ({ children, className, matomoEventType }) => {
  useStyles(s);
  return (
    <div className={classnames(s.Tag, className)} data-matomo={matomoEventType}>
      <span>{children}</span>
    </div>
  );
};

Tag.propTypes = {
  className: string,
  matomoEventType: string,
};

Tag.defaultProps = {
  className: '',
  matomoEventType: '',
};

export default Tag;
