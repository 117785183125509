import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { startsWith } from 'lodash';
import { bool, func, string } from 'prop-types';
import classnames from 'classnames';
import s from './style.less';
import history from '../../utils/history';

const Link = ({ to, onClick, hasLinkStyle, openInNewTab, className, testId, children, matomoEventType }) => {
  useStyles(s);

  const isOutsideLink = startsWith(to, 'http') || startsWith(to, 'mailto:');
  const rel = isOutsideLink ? 'noopener noreferrer' : '';
  const target = isOutsideLink ? '_blank' : undefined;

  const handleClick = (event) => {
    onClick(event);
    if (to === '#') {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
    if (!isOutsideLink) {
      if (openInNewTab) {
        window.open(to, '_blank');
      } else {
        history.push(to);
      }
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <a
      href={to}
      className={classnames(s.Container, hasLinkStyle ? s.LinkStyle : '', className)}
      onClick={handleClick}
      target={target}
      rel={rel}
      data-testid={testId}
      data-matomo={matomoEventType}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  to: string,
  className: string,
  testId: string,
  hasLinkStyle: bool,
  openInNewTab: bool,
  onClick: func,
};

Link.defaultProps = {
  to: '#',
  className: '',
  testId: '',
  hasLinkStyle: true,
  openInNewTab: false,
  onClick: () => {},
};

export default Link;
