import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import moment from 'moment';
import classnames from 'classnames';
import s from './style.less';
import Sitemap from './sitemap';

const Footer = () => {
  useStyles(s);
  const currentYear = moment().year();

  return (
    <footer className={s.Footer}>
      <div className={classnames('page-container', 'col-l-12', s.InnerFooter)}>
        <div className={classnames('col-span-l-10', 'col-start-l-2')}>
          <Sitemap />
          <div className={s.CopyRight}>
            <div>&copy; {currentYear}. Thoughtworks, Inc. All Rights Reserved.</div>
            <div>
              <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
                陕 ICP 备 13005347 号-3
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
