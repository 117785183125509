import React, { useCallback } from 'react';
import RcSelect, { Option as RcOption } from 'rc-select';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { setCookie } from 'shared/utils/cookie';
import { LOCALE } from 'shared/constants/cookies';
import ChineseIcon from '../../../resources/images/icon/icon-language-cn.svg';
import s from './style.less';
import { switchLocale as switchLocaleAction } from '../../../slices/locale';

const MultiLanguageSelect = () => {
  useStyles(s);
  const enableMultiLanguageSelect = useSelector((state) => state.global.enableMultiLanguageSelect);

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const locale = useSelector((state) => state.locale.locale);
  const switchLocale = useCallback(
    (changedValue) => {
      i18n.changeLanguage(changedValue).then(() => {
        dispatch(switchLocaleAction(changedValue));
        setCookie({ name: LOCALE, value: changedValue, expiredDays: 30 });
      });
    },
    [i18n, dispatch]
  );

  if (!enableMultiLanguageSelect) {
    return null;
  }
  return (
    <div className={s.Container}>
      <RcSelect
        id="multi_language_select"
        onChange={(selectedValue) => switchLocale(selectedValue)}
        value={locale}
        inputIcon={<i className="ri-arrow-down-s-line" />}
        className="multi-language-select"
        dropdownClassName="multi-language-select-dropdown"
        showSearch={false}
        optionFilterProp="children"
        dropdownAlign={{ offset: [0, -1] }}
        optionLabelProp="children"
      >
        <RcOption value="cn" key="cn">
          <img className={s.LanguageIcon} src={ChineseIcon} alt="cn" />
          <span>China | 中文</span>
        </RcOption>
        <RcOption value="en" key="en">
          <i className={classNames(s.LanguageIcon, 'ri-global-line', 'global-icon')} />
          <span>Global | English</span>
        </RcOption>
      </RcSelect>
    </div>
  );
};

export default MultiLanguageSelect;
