import React from 'react';
import RcDialog from 'rc-dialog';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './style.less';

const Modal = ({ children, ...props }) => {
  useStyles(s);
  return (
    <RcDialog closeIcon={<i className="ri-close-line" />} {...props}>
      {children}
    </RcDialog>
  );
};

export default Modal;
