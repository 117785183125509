import React, { useCallback } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { bool, func, string } from 'prop-types';
import classnames from 'classnames';
import { isFunction } from 'lodash';
import { useSelector } from 'react-redux';
import Tag from 'shared/vi-components/tag';
import { useTranslation } from 'react-i18next';
import s from './style.less';

const MenuLink = ({ link, label, isNew, isWhiteArrow, pageKey, onClick, testId, matomoEventType }) => {
  useStyles(s);
  const { t } = useTranslation();
  const currentPage = useSelector((state) => state.global.currentPage);
  const isActive = currentPage === pageKey;

  const handleClick = useCallback(
    (event) => {
      if (link === '#') {
        event.preventDefault();
      }
      if (isFunction(onClick)) {
        onClick(event);
      }
    },
    [onClick, link]
  );

  return (
    <a
      href={link}
      className={classnames(s.MenuLink, isActive ? s.Active : '')}
      onClick={handleClick}
      data-testid={testId}
      data-matomo={matomoEventType}
    >
      <div className={s.Content}>
        <span>{label}</span>
        {isNew && <Tag className={s.NewTag}>{t('tag.new')}</Tag>}
      </div>
      <i className={classnames('ri-arrow-down-s-line', s.Arrow, isWhiteArrow ? s.WhiteArrow : '')} />
    </a>
  );
};

MenuLink.propTypes = {
  link: string,
  pageKey: string,
  label: string.isRequired,
  isNew: bool,
  isWhiteArrow: bool,
  onClick: func,
};

MenuLink.defaultProps = {
  link: '#',
  pageKey: '',
  isNew: false,
  isWhiteArrow: true,
  onClick: null,
};

export default MenuLink;
