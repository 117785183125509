import React, { useContext, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Link from 'shared/vi-components/link';
import { disableBodyScroll, recoverBodyScroll } from 'shared/utils/dom-helper';
import { matomoEventTypeMap, MOBILE_CATEGORY_PREFIX, WEB_CATEGORY_PREFIX } from 'shared/utils/matomoConfig';
import { matomoEventTracker } from 'shared/utils/matomoHook';
import { MobileContext } from 'shared/contexts';
import s from './style.less';
import HeaderMenuList from '../header-menu-list';
import MultiLanguageSelect from '../multi-language-select';

const FoldedHeaderMenu = () => {
  useStyles(s);
  const { t } = useTranslation();
  const { isMobile } = useContext(MobileContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleMenu = () => {
    if (isModalOpen) {
      recoverBodyScroll();
    } else {
      disableBodyScroll();
    }
    matomoEventTracker(
      !isModalOpen ? matomoEventTypeMap.nav.menuIconOpen : matomoEventTypeMap.nav.menuIconClose,
      isMobile ? MOBILE_CATEGORY_PREFIX : WEB_CATEGORY_PREFIX
    );
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className={s.Container}>
      <Link hasLinkStyle={false} onClick={toggleMenu} testId="toggle-mobile-menu">
        <span className={s.IconText}>{t('header.menu')}</span>
        <i className="ri-menu-line menu-icon" />
      </Link>
      {isModalOpen && (
        <div className={classNames(s.HeaderMenuModal)}>
          <div className={s.ModalHeader}>
            <Link hasLinkStyle={false} onClick={toggleMenu}>
              <span className={s.IconText}>{t('header.close')}</span>
              <i className="ri-close-line close-icon" />
            </Link>
            <MultiLanguageSelect />
          </div>
          <HeaderMenuList />
        </div>
      )}
    </div>
  );
};

export default FoldedHeaderMenu;
