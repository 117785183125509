// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Oeyp {\n  color: #000;\n  cursor: pointer;\n}\n._3nGzL {\n  color: #bd4257;\n  text-decoration: underline;\n}\n@media (min-width: 992px) {\n  ._3nGzL:hover {\n    color: #9b293c;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"Container": "_1Oeyp",
	"LinkStyle": "_3nGzL"
};
module.exports = exports;
