import React, { useCallback, useContext, useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import history from 'shared/utils/history';
import classnames from 'classnames';
import { loginPage, logoutPage, managementPage, profilePage } from 'shared/constants/pages';
import { getCurrentAccount, getRole } from 'shared/selectors/account';
import { MobileContext } from 'shared/contexts';
import { roles } from 'shared/constants/role';
import Link from 'shared/vi-components/link';
import { logout as logoutAction } from 'shared/slices/auth';
import { toggleWechatLoginModal } from 'shared/slices/global';
import { matomoEventTypeMap } from 'shared/utils/matomoConfig';
import MenuLink from '../menu-link';
import s from './style.less';

const HeaderMenuUserInfo = () => {
  useStyles(s);
  const dispatch = useDispatch();
  const { isMobile } = useContext(MobileContext);
  const { t } = useTranslation();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const role = useSelector((state) => getRole(state));
  const currentAccount = useSelector((state) => getCurrentAccount(state));
  const isLogin = currentAccount !== null;
  const isActive = useCallback((link) => {
    const currentPath = get(history, 'location.pathname', '');
    return `/${link}` === currentPath || `/${link}` === `${currentPath}/`;
  }, []);

  const openLoginModal = useCallback(() => {
    dispatch(toggleWechatLoginModal(true));
  }, [dispatch]);

  const logout = useCallback(async () => {
    const currentPath = get(history, 'location.pathname', '');
    await dispatch(logoutAction());
    history.push(currentPath);
  }, [dispatch]);

  const handleClickTrigger = useCallback(
    (event) => {
      setIsSubMenuOpen(!isSubMenuOpen);
      event.stopPropagation();
    },
    [isSubMenuOpen, setIsSubMenuOpen]
  );

  const handleClickSubMenuContainer = (event) => {
    event.stopPropagation();
  };

  const closeSubMenu = () => setIsSubMenuOpen(false);

  useEffect(() => {
    window.addEventListener('click', closeSubMenu);
    return () => {
      window.removeEventListener('click', closeSubMenu);
      setIsSubMenuOpen(false);
    };
  }, []);

  const helloWording = isLogin ? t('header.hello', { name: currentAccount.name }) : '';
  return isLogin ? (
    <div className={classnames(s.Container, isSubMenuOpen ? s.IsOpen : '')}>
      <Link hasLinkStyle={false} className={s.SubMenuTrigger} onClick={handleClickTrigger}>
        <span title={helloWording}>{helloWording}</span>
        <i className={classnames('ri-arrow-down-s-line', s.ArrowIcon)} />
      </Link>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <section className={s.SubMenu} onClick={handleClickSubMenuContainer}>
        <div className={s.SubMenuItem}>
          <MenuLink
            label={t(profilePage.textKey)}
            link={profilePage.link}
            pageKey={profilePage.key}
            isWhiteArrow={false}
            matomoEventType={profilePage.matomoEventType}
          />
        </div>
        {role === roles.ADMIN && !isMobile && (
          <div className={s.SubMenuItem}>
            <MenuLink
              label={t(managementPage.textKey)}
              link={managementPage.link}
              pageKey={managementPage.key}
              isActive={isActive(managementPage.link)}
              isWhiteArrow={false}
              matomoEventType={managementPage.matomoEventType}
            />
          </div>
        )}
        <div className={s.SubMenuItem}>
          <MenuLink label={t(logoutPage.textKey)} onClick={logout} isWhiteArrow={false} matomoEventType={logoutPage.matomoEventType} />
        </div>
      </section>
    </div>
  ) : (
    <MenuLink label={t(loginPage.textKey)} onClick={openLoginModal} matomoEventType={matomoEventTypeMap.nav.login} />
  );
};

export default HeaderMenuUserInfo;
