import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import remixIcon from 'remixicon/fonts/remixicon.css';
import grid from 'shared/vi-components/styles/grid_.less';
import { useMatomoEventTracker } from 'shared/utils/matomoHook';
import Footer from '../footer';
import Header from '../header';
import s from './style.less';

const Layout = ({ children }) => {
  useStyles(remixIcon, grid, s);
  useMatomoEventTracker();

  return (
    <div>
      <Header />
      <div className={s.MainContent}>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
