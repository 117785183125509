import React, { useCallback, useContext } from 'react';
import WechatLoginModal from 'shared/ui-components/wechat-login-modal';
import { useDispatch, useSelector } from 'react-redux';
import { toggleWechatLoginModal } from 'shared/slices/global';
import { getConfig } from 'shared/utils/config-helper';
import { matomoEventTracker } from 'shared/utils/matomoHook';
import { MobileContext } from 'shared/contexts';
import { matomoEventTypeMap } from 'shared/utils/matomoConfig';

const Login = () => {
  const { isMobile } = useContext(MobileContext);
  const dispatch = useDispatch();

  const isLoginModalVisible = useSelector((state) => state.global.isLoginModalVisible);
  const closeLoginModal = useCallback(() => {
    dispatch(toggleWechatLoginModal(false));
  }, [dispatch]);

  const login = useCallback(() => {
    const {
      document: { location },
    } = global;
    const { origin, pathname, search } = location;
    const scope = isMobile ? 'snsapi_userinfo' : 'snsapi_login';
    const redirectUrl = encodeURIComponent(pathname ? `${origin}/${pathname}` : origin);

    const url = isMobile ? 'https://open.weixin.qq.com/connect/oauth2/authorize' : 'https://open.weixin.qq.com/connect/qrconnect';
    const appId = getConfig(isMobile ? 'publicAppId' : 'openAppId');

    const deviceType = isMobile ? 'MOBILE' : 'WEB';
    matomoEventTracker(matomoEventTypeMap.weChatLogin.login, deviceType);

    location.href =
      search && new URLSearchParams(search).get('expand')
        ? `${url}?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&state=true#wechat_redirect`
        : `${url}?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}#wechat_redirect`;
  }, [isMobile]);

  return <WechatLoginModal visible={isLoginModalVisible} onClose={closeLoginModal} onClickLogin={login} />;
};

export default Login;
