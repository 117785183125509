import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import ImageLink from 'shared/vi-components/image-link';
import Logo from 'shared/resources/images/thoughtworks-logo.svg';
import Login from 'shared/ui-components/header/login';
import { matomoEventTypeMap } from 'shared/utils/matomoConfig';
import s from './style.less';
import FoldedHeaderMenu from './folded-header-menu';
import MultiLanguageSelect from './multi-language-select';
import HeaderMenuList from './header-menu-list';

const Header = () => {
  useStyles(s);

  return (
    <header className={s.Header}>
      <div className={s.InnerHeader}>
        <div className={s.Logo}>
          <ImageLink to="/" imgSrc={Logo} imageAlt="logo" matomoEventType={matomoEventTypeMap.nav.logo} />
        </div>
        <div className={s.ExpandedHeaderMenu}>
          <HeaderMenuList />
          <MultiLanguageSelect />
        </div>
        <FoldedHeaderMenu />
        <Login />
      </div>
    </header>
  );
};

export default Header;
