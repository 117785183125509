import React from 'react';
import { func, object, oneOfType, string } from 'prop-types';
import Link from '../link';

const ImageLink = ({ to, onClick, imgSrc, imageAlt, className, matomoEventType }) => {
  return (
    <Link className={className} hasLinkStyle={false} to={to} onClick={onClick} matomoEventType={matomoEventType}>
      <img src={imgSrc} alt={imageAlt} />
    </Link>
  );
};

ImageLink.propTypes = {
  imgSrc: oneOfType([object, string]).isRequired,
  imageAlt: string.isRequired,
  className: string,
  to: string,
  onClick: func,
};

ImageLink.defaultProps = {
  to: '#',
  className: '',
  onClick: () => {},
};

export default ImageLink;
