import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { sitemapData } from 'shared/constants/sitemap';
import s from './style.less';
import SitemapGroup from './sitemapGroup';

const Sitemap = () => {
  useStyles(s);

  return (
    <div className={s.Container}>
      {sitemapData.map((group) => (
        <SitemapGroup key={group.label.textKey} groupData={group} />
      ))}
    </div>
  );
};

export default Sitemap;
