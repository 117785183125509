import React, { useContext, useState } from 'react';
import { bool, func } from 'prop-types';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from 'react-i18next';
import remixIcon from 'remixicon/fonts/remixicon.css';
import classnames from 'classnames';
import Link from 'shared/vi-components/link';
import { matomoEventTypeMap, MOBILE_CATEGORY_PREFIX, WEB_CATEGORY_PREFIX } from 'shared/utils/matomoConfig';
import { matomoEventTracker } from 'shared/utils/matomoHook';
import { MobileContext } from 'shared/contexts';
import s from './style.less';
import Modal from '../modal';

const WechatLoginModal = ({ visible, onClose, onClickLogin }) => {
  useStyles(s, remixIcon);
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const { isMobile } = useContext(MobileContext);

  return (
    <Modal closable visible={visible} onClose={onClose} className="wechat-dialog">
      <div className={s.Content}>
        <h3 className={s.LoginHeaderInfo}>{t('page.login')}</h3>
        <p className={s.LoginDescription}>{t('page.loginDescription')}</p>
        <Link
          className={classnames(s.WechatButton, isChecked ? '' : s.UnCheckedWechatButton)}
          hasLinkStyle={false}
          onClick={() => {
            matomoEventTracker(matomoEventTypeMap.weChatLogin.loginIcon, isMobile ? MOBILE_CATEGORY_PREFIX : WEB_CATEGORY_PREFIX);
            if (isChecked) {
              onClickLogin();
            }
          }}
        >
          <i className="ri-wechat-fill" />
        </Link>
        <div className={s.CheckBox}>
          <div
            role="button"
            tabIndex={0}
            className={classnames(s.Box, isChecked ? s.BoxChecked : '')}
            onClick={() => {
              matomoEventTracker(matomoEventTypeMap.weChatLogin.policy, isMobile ? MOBILE_CATEGORY_PREFIX : WEB_CATEGORY_PREFIX);
              setIsChecked(!isChecked);
            }}
          >
            {isChecked && <i className={classnames('ri-check-line', s.Hook)} />}
          </div>
          <span>
            {t('page.loginCheck')}
            <Link
              to="https://www.thoughtworks.com/cn/online-application-consent"
              testId="private-policy-link"
              onClick={() => {
                matomoEventTracker(matomoEventTypeMap.weChatLogin.policyLink, isMobile ? MOBILE_CATEGORY_PREFIX : WEB_CATEGORY_PREFIX);
              }}
            >
              {t('page.privatePolicy')}
            </Link>
          </span>
        </div>
      </div>
    </Modal>
  );
};

WechatLoginModal.propTypes = {
  visible: bool.isRequired,
  onClose: func,
  onClickLogin: func,
};

WechatLoginModal.defaultProps = {
  onClose: () => {},
  onClickLogin: () => {},
};

export default WechatLoginModal;
