import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useTranslation } from 'react-i18next';
import { commonPages } from 'shared/constants/pages';
import { useMatomoEventTracker } from 'shared/utils/matomoHook';
import s from './style.less';
import MenuLink from '../menu-link';
import HeaderMenuUserInfo from '../header-menu-user-info';

const HeaderMenuList = () => {
  useStyles(s);
  const { t } = useTranslation();
  useMatomoEventTracker();

  return (
    <nav className={s.Container}>
      <ul>
        {commonPages.map((item) => (
          <li key={item.key}>
            <MenuLink
              link={item.link}
              pageKey={item.key}
              label={t(item.textKey)}
              isNew={item.isNew}
              testId={item.testId}
              matomoEventType={item.matomoEventType}
            />
          </li>
        ))}
        <li>
          <HeaderMenuUserInfo />
        </li>
      </ul>
    </nav>
  );
};

export default HeaderMenuList;
